<i18n>
ru:
  chipsTitle: О нас
  overheadTitle: О нас
  overheadDescription: >
    В нашем ресторане работают настоящие мастера своего дела, что позволяет нам
    оставаться явными лидерами в кулинарной индустрии. Мы дорожим и своим
    именем, и вашим здоровьем, поэтому в составе наших угощений всегда свежие и
    проверенные ингредиенты. Мы постоянно развиваемся и стараемся делать все
    возможное, чтобы не разочаровать вас. Вы обязательно оцените теплую и уютную
    атмосферу, вкусную еду и приветливый персонал. А если у вас нет возможности
    посетить нас, мы с радостью доставим ваш заказ домой или в офис.
ua:
  chipsTitle: Про нас
  overheadTitle: Про нас
  overheadDescription: >
    У нашому ресторані працюють справжні майстри своєї справи, що дозволяє нам
    залишатися явними лідерами в кулінарній індустрії. Ми цінуємо як свою
    репутацію, так і ваше здоров'я, тому в складі наших страв завжди свіжі та
    перевірені інгредієнти. Ми постійно розвиваємося і намагаємося робити все
    можливе, щоб не розчарувати вас. Ви обов'язково оціните теплу та затишну
    атмосферу, смачну їжу та привітний персонал. А якщо у вас немає можливості
    завітати до нас, ми з радістю доставимо ваше замовлення додому або в офіс.
us:
  chipsTitle: About Us
  overheadTitle: About Us
  overheadDescription: >
    Our restaurant employs true masters of their craft, which allows us to
    remain clear leaders in the culinary industry. We value both our reputation
    and your health, therefore our dishes always consist of fresh and verified
    ingredients. We are constantly developing and trying our best to not
    disappoint you. You are sure to appreciate the warm and cozy atmosphere,
    delicious food and friendly staff. And if you do not have the opportunity to
    visit us, we will gladly deliver your order home or to the office.
</i18n>

<template>
  <div class="v-about-us">
    <div class="v-row">
      <h2
        class="v-col-12 v-about-us--title v-mb-sm"
        v-html="translate('aboutUsPage.overheadTitle')"
      />
      <span
        class="v-col-12 v-about-us--description"
        v-html="translate('aboutUsPage.overheadDescription')"
      />
      <div
        v-for="(block, index) in componentBlockAboutUs"
        :key="block.ID"
        class="v-d-flex"
        :class="[
          appConfig.VueSettingsPreRun.AboutUsCardsInRow === 1 ? 'v-col-12' : 'v-col-12 v-col-md-6',
          index === componentBlockAboutUs.length - 1 ? 'v-mb-xxl' : 'v-mb-xl',
          appConfig.VueSettingsPreRun.AboutUsCardsInRow === 1 &&
          appConfig.VueSettingsPreRun.AboutUsCheckeredPattern &&
          index % 2 > 0
            ? 'v-flex-row-reverse'
            : 'v-flex-row'
        ]"
      >
        <div
          v-if="componentBlockAboutUs.some((item: ComponentBlock) => imageExists(item.Image))"
          :class="`${imageExists(block.Image) ? 'v-about-us--image' : 'v-about-us--image v-about-us--image__placeholder'} v-about-us--image__${appConfig.VueSettingsPreRun.AboutUsImageSize} v-about-us--image__direction-${appConfig.VueSettingsPreRun.AboutUsCardsInRow === 1 && appConfig.VueSettingsPreRun.AboutUsCheckeredPattern && index % 2 > 0 ? 'right' : 'left'}`"
        >
          <arora-nuxt-image
            v-if="imageExists(block.Image)"
            :alt="translate('aboutUsPage.overheadTitle')"
            :image="block.Image"
          />
          <icon-general-placeholder-img v-else />
        </div>
        <div
          class="v-about-us--text"
          v-html="sanitize(block.Text)"
        />
      </div>
      <div
        v-if="isImageInfoVideoContent(appConfig.VueSettingsPreRun.AboutUsVideo)"
        class="v-col-12 v-d-flex v-justify-content-center v-about-us--video v-mb-xxl"
      >
        <arora-video
          controls
          :video="appConfig.VueSettingsPreRun.AboutUsVideo"
        />
      </div>
      <div
        v-if="externalLinksAboutUsGallery.length === 1"
        class="v-col-12 v-mb-xxl v-d-flex v-justify-content-center"
      >
        <arora-nuxt-image
          :alt="translate('aboutUsPage.overheadTitle')"
          :image="externalLinksAboutUsGallery[0].Image"
        />
      </div>

      <div
        v-else-if="externalLinksAboutUsGallery.length > 1"
        class="v-col-12 v-mb-xxl v-about-us--gallery"
        :class="{
          'v-about-us--gallery__less_than_two': externalLinksAboutUsGallery.length < 3
        }"
      >
        <arora-swiper-slider
          :items="externalLinksAboutUsGallery"
          :max-items="2"
          :pagination-type="isSmall ? 'none' : 'dynamic-bullets'"
          :show-part-of-next-slide="!!isSmall"
          :space-between="30"
        >
          <template #item="link">
            <arora-nuxt-image
              disable-lazy
              :alt="translate('aboutUsPage.overheadTitle')"
              :image="link.Image"
            />
          </template>
        </arora-swiper-slider>
      </div>
      <div
        v-if="!stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.AboutUsBottomText)"
        class="v-col-12 v-about-us--text v-mb-md"
        v-html="appConfig.VueSettingsPreRun.AboutUsBottomText"
      />
      <div
        v-if="
          imageExists(appConfig.VueSettingsPreRun.AboutUsSEOBannerDesktop) ||
          imageExists(appConfig.VueSettingsPreRun.AboutUsSEOBannerMobile) ||
          imageExists(appConfig.VueSettingsPreRun.AboutUsSEOBannerTablet)
        "
        class="v-col-12 v-mb-xl v-about-us--banner v-elements-overlay"
      >
        <div
          class="v-overlay-element v-h-100 v-about-us--banner-content v-w-100 v-d-flex v-justify-content-center v-align-items-center v-row v-m-0"
        >
          <h2
            v-if="!stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.AboutUsSEOBannerTitle)"
            class="v-about-us--banner-title v-col-10 v-text-center"
            v-html="appConfig.VueSettingsPreRun.AboutUsSEOBannerTitle"
          />
          <span
            v-if="!stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.AboutUsSEOBannerDescription)"
            class="v-col-10 v-about-us--banner-description v-mb-xxl v-text-center"
            v-html="appConfig.VueSettingsPreRun.AboutUsSEOBannerDescription"
          />
          <div
            v-if="
              !stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.AboutUsSEOBannerButton) &&
              !stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.AboutUsSEOBannerButtonLink)
            "
            class="v-col-12 v-text-center"
          >
            <arora-button
              class-name="v-w-auto v-about-us--btn v-btn v-btn-primary"
              ignore-settings
              :label="appConfig.VueSettingsPreRun.AboutUsSEOBannerButton"
              @click="bannerClick"
            />
          </div>
        </div>
        <div class="v-overlay-element">
          <template v-if="isDesktopVersion">
            <arora-nuxt-image
              v-if="imageExists(appConfig.VueSettingsPreRun.AboutUsSEOBannerDesktop)"
              :alt="translate('partnersPage.buttonDefaultText')"
              :image="appConfig.VueSettingsPreRun.AboutUsSEOBannerDesktop"
            />
            <div
              v-else
              class="v-about-us--banner-placeholder"
            >
              <icon-general-placeholder-img />
            </div>
          </template>
          <template v-else-if="isExtraSmall">
            <arora-nuxt-image
              v-if="imageExists(appConfig.VueSettingsPreRun.AboutUsSEOBannerMobile)"
              :alt="translate('partnersPage.buttonDefaultText')"
              :image="appConfig.VueSettingsPreRun.AboutUsSEOBannerMobile"
            />

            <div
              v-else
              class="v-about-us--banner-placeholder"
            >
              <icon-general-placeholder-img />
            </div>
          </template>
          <template v-else>
            <arora-nuxt-image
              v-if="imageExists(appConfig.VueSettingsPreRun.AboutUsSEOBannerTablet)"
              :alt="translate('partnersPage.buttonDefaultText')"
              :image="appConfig.VueSettingsPreRun.AboutUsSEOBannerTablet"
            />

            <div
              v-else
              class="v-about-us--banner-placeholder"
            >
              <icon-general-placeholder-img />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComponentBlock, ExternalLink } from '~types/settingsVariants'

import { useCommon, useWindowSize } from '@arora/common'

const appConfig = useAppConfig()
const { isImageInfoVideoContent, imageExists } = useImageInfo()
const { stringIsNullOrWhitespace } = useCommon()
const { translate, sanitize } = useI18nSanitized()
const { isDesktopVersion, isExtraSmall } = useWindowSize()

const { isSmall } = useWindowSize()

const componentBlockAboutUs = ref<ComponentBlock[]>(
  appConfig.VueSettingsPreRun.ComponentBlocks.filter((l) => l.Type === 'AboutUs').sort(
    (a, b) => a.SortWeight - b.SortWeight
  )
)

const externalLinksAboutUsGallery = ref<ExternalLink[]>(
  appConfig.VueSettingsPreRun.ExternalLinks.filter((l) => l.Type === 'aboutUsGallery').sort(
    (a, b) => a.SortWeight - b.SortWeight
  )
)

function bannerClick(): void {
  navigateTo(appConfig.VueSettingsPreRun.AboutUsSEOBannerButtonLink, {
    external: !appConfig.VueSettingsPreRun.AboutUsSEOBannerButtonLink.startsWith('/')
  })
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-about-us {
  &--title {
    font-weight: 600;
    font-size: 1.715rem;
  }

  &--description {
    font-size: 1.1rem;
    margin-bottom: 40px;
  }

  &--image {
    height: fit-content;
    color: variables.$BodyTextColorLight;

    &__placeholder {
      background: variables.$BodyBackgroundDarken;
      color: variables.$BodyTextColorLight;

      border-radius: variables.$BorderRadius;

      display: flex;
      justify-content: center;
      align-items: center;

      aspect-ratio: 1/1;
    }

    &__direction-left {
      margin-right: 1rem;
    }

    &__direction-right {
      margin-left: 1rem;
    }

    &__default {
      width: 32%;

      &.v-about-us--image__placeholder {
        padding: 40px;
      }

      @include mixins.sm {
        float: none;
        margin-bottom: 1rem;
        margin-left: 0;

        width: 50%;
        &.v-about-us--image__placeholder {
          padding: 10px;
        }
      }
    }

    &__half {
      width: 50%;

      &.v-about-us--image__placeholder {
        padding: 50px;
      }

      @include mixins.sm {
        float: none;
        margin-bottom: 1rem;
        margin-left: 0;

        &.v-about-us--image__placeholder {
          padding: 10px;
        }
      }
    }

    &__icon {
      width: 14%;

      &.v-about-us--image__placeholder {
        padding: 20px;
      }

      @include mixins.sm {
        width: 32%;
        &.v-about-us--image__placeholder {
          padding: 10px;
        }
      }
    }

    .v-img-fluid {
      width: 100%;
      height: auto;
      border-radius: variables.$BorderRadius;
      overflow: hidden;
      aspect-ratio: 1/1;
    }

    svg {
      width: 100%;
      height: auto;
      fill: none;
    }
  }

  &--gallery {
    &__less_than_two {
      .v-swiper .swiper-wrapper {
        padding-bottom: 1.5rem;

        @include mixins.sm {
          padding-bottom: 50px;
        }
      }
    }

    .v-img-fluid {
      border-radius: variables.$BorderRadius;
      overflow: hidden;
    }
  }

  &--text {
    flex: 1;
    font-size: 1.1rem;

    h3 {
      margin: 0;
    }

    p {
      margin: 0;
    }

    ul {
      list-style: disc;
      padding-left: 2.5rem;
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    ol {
      list-style: disc;
      padding-left: 2.5rem;
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &--video {
    video {
      border-radius: variables.$BorderRadius;
      overflow: hidden;
      box-shadow: variables.$InputShadow;
    }
  }

  &--banner {
    .v-img-fluid {
      width: 100%;
      height: auto;
      border-radius: variables.$BorderRadius;
      overflow: hidden;
      box-shadow: variables.$BannerShadow;
    }

    &-content {
      height: 75%;
      color: variables.$BannersTextColor;
      z-index: 2;
      padding-top: 80px;
      padding-bottom: 80px;

      @include mixins.xxl {
        padding-top: 65px;
        padding-bottom: 65px;
      }

      @include mixins.xl {
        padding-top: 55px;
        padding-bottom: 55px;
      }

      @include mixins.lg {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      @include mixins.md {
        padding-top: 45px;
        padding-bottom: 45px;
      }

      @include mixins.sm {
        padding-top: 35px;
        padding-bottom: 35px;
      }

      @include mixins.xs {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
  }

  &--banner-title {
    font-weight: 600;
    font-size: 2rem;

    @include mixins.xl {
      margin-bottom: 2rem;
    }

    @include mixins.lg {
      margin-bottom: 1.75rem;
    }

    @include mixins.md {
      margin-bottom: 1.5rem;
    }

    @include mixins.sm {
      margin-bottom: 1.25rem;
    }

    @include mixins.xs {
      margin-bottom: 1rem;
    }
  }

  &--banner-description {
    font-size: 1.425rem;
    margin-bottom: 2.5rem;

    @include mixins.sm {
      margin-bottom: 1.5rem;
    }

    @include mixins.md {
      margin-bottom: 1.75rem;
    }

    @include mixins.lg {
      margin-bottom: 2rem;
    }
  }

  &--banner-placeholder {
    background: variables.$BodyBackgroundDarken;
    color: variables.$BodyTextColorLight;

    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 50px 0;

    svg {
      width: 170px;
      height: 170px;
    }
  }

  &--btn {
    padding: 10px 16px;
    font-size: 1.285rem;
  }
}
</style>
